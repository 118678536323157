<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cadastro de Roles</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: item, getItens: getItens, jsonData: false }" />
            </template>

            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-end">
                    <v-btn
                      v-if="permiteAcao({path: '/gestao/permissoes'}, '')"
                      small
                      @click="addPermission(item.id)"
                      tile
                      outlined 
                      :color="variables.colorSecondary"
                      class="mr-2"
                    >
                      + Persmissões
                    </v-btn>

                    <v-btn
                      v-if="permiteAcao($route, 'edit')"
                      small
                      @click="editItem(item)"
                      tile
                      outlined 
                      :color="variables.colorPrimary"
                      class="mr-2"
                    >
                      Editar
                    </v-btn>

                    <v-btn
                      v-if="permiteAcao($route, 'delete')"
                      small
                      @click="deleteItem(item)"
                      tile
                      outlined 
                      :color="variables.colorError"
                    >
                      Excluir
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// import Alert from 'sweetalert2'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'Cadastro',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('roles', ['listaItens', 'permiteAcao', 'totalItens', 'item']),
  },
  methods: {
    ...mapActions('roles', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens']),
    addPermission (item) {
      this.$router.push(`/gestao/permissoes/${item}`)
    },
  },
}
</script>

<style lang="scss" src="@/assets/styles/view/roles.scss"></style>>
